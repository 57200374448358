import React from 'react';
import Head from 'next/head';
import Router from 'next/router';

const NotFound = () => {
  return (
    <>
      <Head>
        <title>Page Not Found</title>
      </Head>
      <div className="not-found__wrapper d-flex align-items-center justify-content-center flex-column">
        <div className="position-relative not-found__image-container">
          <img className="illustration-image" src="/notfound/illustration.svg"></img>
          <img className="notfound-image position-absolute " src="/notfound/404.svg"></img>
        </div>
        <div className="not-found__description mt-3">
          <div className="title">Oops!</div>
          <div className="subtitle">Page Not Found.</div>
        </div>
        <div className="not-found___button-container mt-3">
          <button className="button button-outline" onClick={() => Router.push('/')}>
            Back
          </button>
        </div>
      </div>
    </>
  );
};

export default NotFound;
